import { ShimmerTable } from "react-shimmer-effects";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./HrList.css";
import { toast } from "react-hot-toast";
import { fetchCollageInfoList } from "../../api/fetchContactList";
import unprotectedApiClient from "../../services/unprotectedApi";

const ContactList = () => {
  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleVerification = async (id) => {
    try {
      const response = await unprotectedApiClient.post("/api/verifycollege/", { id });
      fetchData();
      return response.data;
    } catch (error) {
      console.error("Failed to fetch College List:", error);
      throw error;
    }
  };
  
  const fetchData = async () => {
    try {
      const data = await fetchCollageInfoList(); // Fetch the contact list
      setContactList(data); // Update state with the fetched data
      setIsLoading(false); // Set loading to false
      console.log(contactList)
    } catch (error) {
      toast.error("Failed to fetch contact list");
      console.error("Error fetching data:", error);
      setIsLoading(false); // Stop loading even if there's an error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="projects-section">
      <div className="projects-section-header">
        <p>Contacts List</p>
      </div>
      {isLoading ? (
        <ShimmerTable row={6} col={5} className="shimmer-table-effect" />
      ) : (
        <div className="hr-table-container overflow-auto">
          <table className="hr-table">
            <thead>
              <tr>
                <th>Collage Name</th>
                <th>Domain</th>
                <th>User Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {contactList.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    No contacts found
                  </td>
                </tr>
              ) : (
                contactList.map((contact) => (
                  <tr key={contact.id}>
                    <td>{contact.name}</td>
                    <td>{contact.subdomain}</td>
                    <td>{contact.user.username}</td>
                    <button
                      onClick={() => handleVerification(contact.id)}
                      className="bg-green-400 px-2 py-1 rounded-md"
                    >
                      {contact.isVerified ? "Verified" : "Verify"}
                    </button>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ContactList;